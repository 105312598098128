<template>
  <div>
    <div class="main-loader" v-if="isLoading"></div>
    <div class="agents-page" v-else>
      <b-container>

        <!-- Create Agent Banner -->
        <div v-if="showBanner" class="create-agent-banner">
          <!-- Close Button -->
          <button class="close-banner-btn" @click="closeBanner">&times;</button>

          <h3>Create Your Own Creative Agent</h3>
          <p>Upload your images, process them with our AI to build your own creative agents tailored to your needs — perfect for creating photoshoot images

          </p>
          <b-button variant="secondary" @click="goToCreateAgent" class="upload-btn">
          <feather-icon size="16" icon="ZapIcon"/>
          Create New Agent
          </b-button>
          </div>


        <b-row class="swiper-container mt-1">
            <!-- <swiper :options="swiperOptions" class="swiper-container mt-1">
              <swiper-slide v-for="(tab, index) in tabs" :key="index" class="ss-width">
                <b-button 
                  :variant="isSelected(tab.category) ? 'dark' : 'outline-secondary'" 
                  @click="toggleCategory(tab.category)" 
                  class="tab-button">
                  {{ tab.category }}
                </b-button>
              </swiper-slide>
            </swiper> -->
          </b-row>

        <!-- Agent Cards -->
        <b-row v-if="filteredAgents.length">
          <agent-card 
            v-for="agent in paginatedAgents"
            :key="agent.agentId" 
            :agent="agent"
            @run-agent="runAgent(agent.agentId)"
            :showShareButton="false"
            :showUserExecutionCount="false" 
          />
        </b-row>

        <!-- No Data Found Message -->
        <div class="no-data mt-5 mb-5" v-else>
          <div class="mx-auto text-center">
            <h2>No agents found. Explore and add agents to this page.</h2>
          </div>
        </div>

        <!-- Infinite Scroll Trigger -->
        <div ref="loadMoreTrigger" class="infinite-scroll-trigger"></div>
      </b-container>
    </div>
  </div>
</template>
  
  <script>
  import agentCard from './agentCard.vue'; // Your agentCard component
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
  
  export default {
    name: 'AgentsPage',
    components: {
      Swiper,
      SwiperSlide,
      agentCard,
    },
    data() {
      return {
        isLoading: true,
        showBanner: true,
        tabs: [], // Dynamic categories from agents
        selectedCategories: [], // Array to store selected categories
        agentsPerPage: 100, // Number of agents per page for pagination
        currentPage: 1,
        swiperOptions: {
          slidesPerView: 'auto',
          spaceBetween: 8,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            1024: {
              slidesPerView: 'auto',
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 'auto',
              spaceBetween: 6,
            },
            640: {
              slidesPerView: 'auto',
              spaceBetween: 4,
            },
            320: {
              slidesPerView: 'auto',
              spaceBetween: 2,
            },
          },
        },
      };
    },
    computed: {
      ...mapGetters('app', ['allAgents']),
      
      filteredAgents() {
        if (this.selectedCategories.length > 0) {
          return this.allAgents.filter(agent => {
            const agentCategories = Array.isArray(agent.category) 
              ? agent.category 
              : agent.category.split(',').map(cat => cat.trim());
            return agentCategories.some(cat => this.selectedCategories.includes(cat));
          });
        }
        return this.allAgents;
      },
      
      paginatedAgents() {
        return this.filteredAgents.slice(0, this.agentsPerPage * this.currentPage);
      },
  
      hasMoreAgents() {
        return this.paginatedAgents.length < this.filteredAgents.length;
      },
    },
    async created() {
      await this.fetchAgents(); // Await the fetching of agents
      this.generateTabs(); // Create the tabs dynamically based on agents
      this.isLoading = false; // Loading completed
      const bannerPreference = localStorage.getItem('showAgentBanner');
    this.showBanner = bannerPreference !== 'false';
    },
    mounted() {
      this.initializeIntersectionObserver(); // Set up infinite scroll
    },
    methods: {
      ...mapActions('app', ['fetchAgents']),
      
      runAgent(agentId) {
        this.$router.push(`/agents/${agentId}`);
      },
      
      generateTabs() {
  const categories = new Set();
  this.allAgents.forEach(agent => {
    const agentCategories = Array.isArray(agent.category) 
      ? agent.category 
      : agent.category.split(',').map(cat => cat.trim());
    
    agentCategories.forEach(cat => categories.add(cat));
  });

  this.tabs = Array.from(categories).map(category => ({
    category,
    active: this.isSelected(category),
  }));

  // Do NOT set any category by default
  // this.selectedCategories = [this.tabs[0].category];
},
      
      toggleCategory(category) {
        if (this.isSelected(category)) {
          this.selectedCategories = this.selectedCategories.filter(selected => selected !== category);
        } else {
          this.selectedCategories.push(category);
        }
        this.currentPage = 1; // Reset pagination when the category changes
      },
      
      isSelected(category) {
        return this.selectedCategories.includes(category);
      },
      
      initializeIntersectionObserver() {
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0.1,
        };
        this.observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.loadMoreAgents();
            }
          });
        }, options);
        if (this.$refs.loadMoreTrigger) {
          this.observer.observe(this.$refs.loadMoreTrigger);
        }
      },
      goToCreateAgent() {
  const userData = localStorage.getItem("userData");
  if (!userData) {
    this.$bvModal.show("modal-login");
    return;
  }
  this.$router.push('/products');
},
    closeBanner() {
      // Hide the banner and store preference
      this.showBanner = false;
      // localStorage.setItem('showAgentBanner', 'false');
    },
      
      loadMoreAgents() {
        if (this.hasMoreAgents) {
          this.currentPage++;
        }
      },
    },
  };
  </script>
  
  <style scoped>  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: auto;
    box-sizing: border-box;
  }
  
  .swiper-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    margin-top: 15px;
  }
  
  .swiper-container::-webkit-scrollbar {
    display: none;
  }
  
  .tab-button {
    margin: 0 5px;
  }
  
  .infinite-scroll-trigger {
    width: 100%;
    height: 50px;
  }
  /* Swiper and tab styles */
.swiper-slide {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: auto !important;
  box-sizing: border-box;
}

.swiper-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-left: 6px !important;
  margin-right: 6px !important;
  margin-top: 15px !important;
  padding-bottom: 10px !important;
}

.swiper-container::-webkit-scrollbar {
  display: none;
}

.tab-button {
  font-size: 1rem !important;
  margin-bottom: 8px;
  margin-top: 2px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0.48571rem 0.71429rem !important;
  display: inline-flex;
  align-items: center !important;
  justify-content: center !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: hsl(0, 0%, 100%);
  transition: all 0.2s ease-in-out;
}

.upload-btn {
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 8px;
  margin-left:0rem;
  margin-bottom: 1rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.upload-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

/* Add pulse animation for extra attention */
@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(0, 255, 213, 0.5); }  /* Bright teal */
  70% { box-shadow: 0 0 0 10px rgba(0, 255, 213, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 255, 213, 0); }
}

.upload-btn:not(:hover) {
  animation: pulse 2s infinite;
}

.tab-button:hover,
.tab-button.is-active {
  background-color: #0069d9;
  color: white;
}

.b-button {
  border-radius: 0.25rem;
}

.ss-width {
  width: auto !important;
}
.agents-page {
  margin-left: auto;
  margin-right: auto;
}
.b-container {
  padding: 0 20px; /* Padding for the container to align content nicely */
}

.no-data {
  text-align: center;
  padding: 20px;
}
.create-agent-banner {
  position: relative;  
  background-color: #000000; /* Black background */
  color: #ffffff;            /* White text */
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 0px;
  height: 100%;
  width: 100%;
}

.create-agent-banner h3 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 1.7rem;
  padding-top: 10px;
  color: white;
}

.create-agent-banner p {
  margin: 0;
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 1rem;
}
/* Close Button Styles */
.close-banner-btn {
  position: absolute;
  top: 0px;
  right: 15px;
  background: transparent;
  font-size: 24px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.close-banner-btn:hover {
  color: #cccccc;
}

.create-agent-banner .btn-primary {
  background-color: #38c03f;
  border-color: #007bff;
  color: #28a745;
}


.create-agent-banner .btn-primary:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.create-agent-banner .btn-primary:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

/* Responsive Design */
@media (max-width: 600px) {
  .create-agent-banner {
    padding: 15px;
  }

  .create-agent-banner h3 {
    font-size: 1.25rem;
  }

  .create-agent-banner p {
    font-size: 1rem;
  }
}


  </style>
  