<template>
<div class="profile-view">

    <!-- Tabs -->
    <div class="profile-tabs mt-2 mt-md-3">
        <b-container>
            <b-row>
                <b-col md="12">
                    <div class="">

                        <profile-dashboard />
                        <!-- <b-tabs pills align="center" class="profile-tab"> -->
                            <!-- <b-tab class="pro-nav" :active='isProfile'>
                                <template #title>
                                    <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
                                    <span class="d-none d-sm-inline">Profile</span>
                                </template>

                                <div class="">
                                    <profile-dashboard />
                                </div>
                            </b-tab> -->

                   
                            <!-- <b-tab>
                                <template #title>
                                    <feather-icon icon="SettingsIcon" size="16" class="mr-0 mr-sm-50" />
                                    <span class="d-none d-sm-inline">Account settings</span>
                                </template>

                                <div class="">
                                    <users-profile-edit />
                                </div>
                            </b-tab> -->
                            <!-- <b-tab>
                                <template #title>
                                    <feather-icon icon="SettingsIcon" size="16" class="mr-0 mr-sm-50" />
                                    <span class="d-none d-sm-inline">Billing</span>
                                </template>

                                <div class="">
                                    <invoices />
                                </div>
                            </b-tab> -->
                        <!-- </b-tabs> -->
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>

</div>
</template>

<script>
import {
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BNavItem,
    BContainer,
    BRow,
    BCol,
    BImg,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BBadge,
    BLink,
    BTabs,
    BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import UserDropdown from './components/UserDropdown.vue'
import ProfileDashboard from './usersprofile/ProfileDashboard.vue'
import MyCollections from './benefits/MyCollections.vue'

import MyBilling from './billings/MyBilling.vue'
import paymentMethods from './billings/PaymentMethods.vue'
import UsersProfileEdit from './usersprofile/users-profile-edit/UsersProfileEdit.vue'

export default {
    components: {
        BNavbar,
        BNavbarBrand,
        BNavbarToggle,
        BCollapse,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BNavItem,
        BContainer,
        BRow,
        BCol,
        BCard,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
        BCardText,
        BImg,
        BBadge,
        BLink,
        BTabs,
        BTab,
        vSelect,
        flatPickr,

        UserDropdown,
        ProfileDashboard,
        MyCollections,

        MyBilling,
        UsersProfileEdit,
    },
    data() {
        return {
          
            isBenefits: false,
            isProfile: true

        }
    },
    created() {

        this.checkUserLoggedIn();
    },
    methods: {
        checkUserLoggedIn() {
            const userData = localStorage.getItem("userData")

            if (userData == null || undefined) {
                this.$router.push('/index')

            } else {
                if (this.$route.query.id == true) {
                    this.isProfile = true
                } else if (!this.$route.query.id) {
                    this.isBenefits = true
                    this.isProfile = false
                }
            }

        },

    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.profile-tabs .profile-tab .nav-link {
    padding: .75rem 1rem;
    border-radius: 25px;
    background: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14) !important;
    margin-right: 1rem;
}

.profile-tabs .profile-tab .nav-link.active {
    background: #6c757d !important;
    border-bottom: none !important;
    border-color: #6c757d !important;
}

@media (max-width: 767.98px) {}
</style>
